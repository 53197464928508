<template>
  <div v-if="step !== null" class="tour-overlay">
    <div  :style="popoverStyle" :class="popoverClasses">
      <div :class="arrowClass"></div>
      <v-card-title style="color:#5474FB; padding-bottom: 0; padding-top: 0;" class="justify-center">

        {{ steps[step].title }}</v-card-title>
      <v-card-text v-html="steps[step].description" style="padding-left: 0;padding-right: 0;" >

      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer/>
        <!--        <button style="color:#5474FB" @click="nextStep">Aceptar</button>-->
        <v-btn text class="text-none" @click="terminate">Omitir</v-btn>
        <v-btn @click="handleNextStep" class="text-none elevation-0" color="primary" >Aceptar</v-btn>
      </v-card-actions>
    </div>
    <div class="tour-backdrop"></div>
  </div>
</template>


<script>
export default {
  name: 'TourGuideHuella',
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      step: null,
    };
  },
  computed: {
    popoverClasses() {
      return [
        'tour-popover',
        // this.steps[this.step].title === '' ? 'mt-10' : null,
        this.steps[this.step].title === 'Reporte' ? 'mt-n10 mr-15' : null,
        // this.steps[this.step].title === 'Editar' ? 'mt-15' : null,

      ]
    },
    popoverStyle() {
      if (this.step === null) return {};
      return this.calculatePopoverStyle();
    },
    arrowClass() {
      switch (this.steps[this.step].position) {
        case 'top':
          return 'tour-arrow-top';
        case 'right':
          return 'tour-arrow-right';
        case 'bottom':
          return 'tour-arrow-bottom';
        case 'left':
          return 'tour-arrow-left';
        default:
          return 'tour-arrow-bottom';
      }
    },
  },
  methods: {
    start() {
      this.step = 0;
      window.addEventListener('scroll', this.updatePopoverPosition, { passive: true });
    },
    handleNextStep() {
      this.executeAction();
      this.nextStep();
    },
    terminate() {
      this.step = null;
      window.removeEventListener('scroll', this.updatePopoverPosition);
      let tour = JSON.parse(localStorage.getItem('tour'));
      if (tour) {
        tour = { one: true, two: true, three: true };
        localStorage.setItem('tour', JSON.stringify(tour));
      }
      this.$router.replace({ path: this.$route.path });
    },
    nextStep() {
      if (this.step < this.steps.length - 1) {
        this.step++;
        this.updatePopoverPosition();
      } else {
        this.terminate();
      }
    },
    executeAction() {
      if (this.steps[this.step].action === 'drawer') {
        this.$emit('abrirdrawer');
      } else if (this.steps[this.step].action === 'huella-de-carbono') {
        this.$emit('huella-de-carbono');
      } else if (this.steps[this.step].action === 'scrollone') {
        this.$emit('scrollone');
      } else if (this.steps[this.step].action === 'goHome') {
        this.$emit('goHome');
      }else if (this.steps[this.step].action === 'goTop') {
        this.$emit('goTop');
      }
    },
    updatePopoverPosition() {
      this.$forceUpdate(); // Forzar una actualización para recalcular la posición
    },
    calculatePopoverStyle() {
      const element = document.querySelector(this.steps[this.step].selector);
      const styles = {};

      if (element) {
        const rect = element.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        styles.position = 'fixed';
        styles.zIndex = '9999';

        switch (this.steps[this.step].position) {
          case 'bottom':
            styles.top = `${rect.bottom + window.scrollY + 10}px`; // Reducido a 10px
            styles.left = `${rect.left + window.scrollX + rect.width / 2}px`;
            // styles.transform = 'translate(-50%, 0)';
            break;
          case 'top':
            styles.bottom = `${viewportHeight - rect.top + window.scrollY + 10}px`;
            styles.left = `${rect.left + window.scrollX + rect.width / 2}px`;
            styles.transform = 'translate(-50%, 0)';
            break;
          case 'left':
            styles.top = `${rect.top + window.scrollY + (rect.height / 2)}px`;
            styles.left = `${rect.left + window.scrollX - 5}px`;
            styles.transform = 'translate(-100%, -50%)';
            break;
          case 'right':
            styles.top = `${rect.top + window.scrollY + (rect.height / 2)}px`;
            styles.left = `${rect.right + window.scrollX + 5}px`; // Reducido de 10 a 5
            styles.transform = 'translate(0, -50%)';
            break;
        }

        // Asegúrate de que el tooltip no se salga de la pantalla
        if (parseFloat(styles.top) + 200 > viewportHeight) { // 200 es una altura estimada del tooltip
          styles.top = `${viewportHeight - 220}px`; // 20px de margen
        }
      }

      return styles;
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updatePopoverPosition);
  },
};



</script>

<style scoped>
.tour-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events: none;
}


.tour-popover {
  position: absolute;
  background: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  pointer-events: auto;
  max-width: 400px;
  margin-right: 10px;


}

.tour-popover .tour-arrow-top {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
  bottom: -10px;
  left: 20px;
  transform: translateX(-50%);
}

.tour-popover .tour-arrow-bottom {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  top: -10px;
  left: 20px;
  transform: translateX(-50%);
}

.tour-popover .tour-arrow-left {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
  bottom: -10px;
  right: 20px;
  transform: translateX(-50%);
}

.tour-popover .tour-arrow-right {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid white;
  left: -5px;
  top: 50%;
  transform: translateX(-50%);
}
.tour-popover .aotrolugar {
  position: absolute;
  background: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  pointer-events: auto;
  max-width: 200px;
  transform: translateX(-50%);
}

.tour-popover h3 {
  margin: 0 0 10px 0;
}

.tour-popover button {
  /*margin-top: 10px;*/
}

.tour-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
</style>
